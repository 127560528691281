import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { IPoiFullscreen } from "@core/fullscreen/fullscreen";

@Injectable({
    providedIn: "root",
})
export class FullscreenService {
    public pois$: Observable<IPoiFullscreen | null>;
    private readonly poisSource = new BehaviorSubject<IPoiFullscreen | null>(null);

    public images$: Observable<any[]>;
    private readonly imagesSource = new BehaviorSubject<any[]>([]);

    public constructor() {
        this.pois$ = this.poisSource.asObservable();
        this.images$ = this.imagesSource.asObservable();
    }

    public setPois(pois: IPoiFullscreen | null): void {
        this.poisSource.next(pois);
    }

    public setSimpleImages(images: any[]): void {
        this.imagesSource.next(images);
    }
}
