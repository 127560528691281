import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Experience } from "./models/experience";

@Injectable()
export class ApiStrapiService {
    public constructor(private readonly http: HttpClient) {}

    public getMarcovascoExperiencesVideos(countries: any[], brand: string) {
        const url = `${environment.strapiApiUrl}/mv-assets?populate[0]=country&populate[1]=places&populate[2]=image&populate[3]=asset&filters[type][$eq]=Vidéo&filters[country][iso]=`;

        const promise = new Promise((resolve, reject) => {
            const promiseAll = [];

            for (const country of countries) {
                const headers: HttpHeaders = new HttpHeaders({
                    Authorization: `Bearer ${environment.strapiApiToken}`,
                });

                promiseAll.push(this.http.get(`${url}${country.iso}`, { headers }).toPromise());
            }

            Promise.all(promiseAll)
                .then((response) => {
                    // get only the first result
                    const experienceVideoData = response.map((video: any) => {
                        return video.data && video.data.length > 0 ? video.data[0] : null;
                    });

                    if (!experienceVideoData[0]) {
                        resolve(null);

                        return;
                    }

                    // get attributes.image and attributes.asset
                    const experienceVideo: Experience[] = experienceVideoData.map((video: any) => {
                        const image = video.attributes.image;
                        const asset = video.attributes.asset;

                        return new Experience({
                            image,
                            asset,
                        });
                    });

                    resolve(experienceVideo[0]);
                })
                .catch((error) => {
                    reject(error);
                });
        });

        return promise;
    }
}
